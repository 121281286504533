import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
const NotFoundPage = ({ data, location }) => {
  return (
    <Layout>
      <Head
        title="404: Not Found"
        description="申し訳ありません。お探しのページは見つかりませんでした。"
      />
      <section className="hero is-fullheight">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title">404: Not Found</h1>
            <p>
              申し訳ありません。
              <br />
              お探しのページは見つかりませんでした。
            </p>
            <Link
              to="/"
              className="button is-primary is-medium is-rounded is-fix-size-button mt-6"
            >
              トップページへ
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default NotFoundPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
